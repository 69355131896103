import { Auth_RefreshToken } from '@/chore/services/Auth.service';
import axios from 'axios';
import { env } from '@/lib/env';
import { getRefreshToken } from './functions/authentication';
import logger from '@/lib/client/utils/logger';

const jobreelApi = axios.create({
  baseURL: env.baseApiUrl,
});

jobreelApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (typeof window !== 'undefined') {
      logger.info(`${config.method} ${config.url} request`, {
        data: config.data,
        headers: config.headers,
        query: config.params,
      });
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
jobreelApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (typeof window !== 'undefined') {
      logger.info(`${response.config.method} ${response.config.url} response`, {
        data: response.data,
        status: response.status,
        headers: response.headers,
        request: {
          data: response.config.data,
          headers: response.config.headers,
          query: response.config.params,
        },
      });
    }
    return response;
  },
  async (error) => {
    const errorMessage =
      error.response?.data.message ?? error.message ?? 'Something went wrong.';
    const axiosError = new Error(errorMessage);
    const errorContext: Record<string, any> = {
      requestUrl: error.response.request.responseURL,
      status: error.response.status,
      headers: error.response.headers,
      method: error.response.config.method,
    };
    try {
      const requestBody = JSON.parse(error.response.config.data);
      errorContext.body = requestBody;
    } catch (err: any) {}
    if (typeof window !== 'undefined') {
      logger.error(
        axiosError,
        `${error.response?.status} AXIOS ERROR`,
        errorContext
      );
    }
    const refreshToken = getRefreshToken();

    if (error.response?.status === 401 && !!refreshToken?.length) {
      if (process.env.NODE_ENV === 'development') {
        logger.info('Refreshing Auth Token');
      }
      await Auth_RefreshToken();
      if (process.env.NODE_ENV === 'development') {
        logger.info('Reperforming request');
      }
      return jobreelApi.request(error.request.config);
    } else {
      return Promise.reject(error);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  }
);
export default jobreelApi;
