import jobreelApi from '@/lib/utils/jobreel-api';
import {
  getBearerToken,
  getRefreshToken,
  storeBearerToken,
  storeRefreshToken,
  storeUserId,
} from '@/lib/utils/functions/authentication';
import { ServerResponse } from '@/lib/types';

export const Auth_SendOtp = async (phoneNumber: string) => {
  const { status } = await jobreelApi.post<void>('/v1/auth/phone-number', {
    phone_number: phoneNumber,
  });
  return status;
};

export const Auth_Checkcode = async (phoneNumber: string, code: string) => {
  const { data } = await jobreelApi.post<
    ServerResponse<{
      access_token: string;
      refresh_token: string;
      is_known: boolean;
      user_id?: string;
    }>
  >('/v1/auth/phone-number/check', {
    phone_number: phoneNumber,
    code,
  });
  storeBearerToken(data.data.access_token);
  storeRefreshToken(data.data.refresh_token);
  if (data.data.user_id) {
    storeUserId(data.data.user_id);
  }
  return data.data;
};

export const Auth_RefreshToken = async () => {
  try {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      return;
    }

    const { data } = await jobreelApi.post<
      ServerResponse<{
        access_token: string;
        refresh_token: string;
      }>
    >(
      '/v1/auth/refresh',
      {},
      { headers: { 'x-jobreel-refresh': refreshToken } }
    );
    storeBearerToken(data.data.access_token);
    storeRefreshToken(data.data.refresh_token);
  } catch (e: any) {
    console.error(e);
    localStorage.clear();
    sessionStorage.clear();
    return;
  }
};

export const Auth_Logout = async () => {
  await jobreelApi.patch<ServerResponse<undefined>>(
    '/v1/auth/logout',
    {},
    { headers: { Authorization: getBearerToken() } }
  );
};
